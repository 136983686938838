import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Terms from './style'
export default function Cookies() {
  return (
    <Terms>
      <Container>
        <Row className='justify-content-center'>
          <Col className='col-xl-7 col-lg-8 col-md-11 text-center'>
            <Terms.Box>
              <Terms.Title as='h2'></Terms.Title>
            </Terms.Box>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col className='col-xxl-8 col-xl-9 col-lg-10'>
            <Terms.Content>
              <Terms.Text>
                Selamat datang di platform SoBatRP yang dioperasikan oleh PT
                Sobatrp Solusi Terbarukan dan afiliasinya serta anak
                perusahaannya (secara sendiri-sendiri dan bersama-sama juga
                disebut “SoBatRP”). SoBatRP bertanggung jawab sesuai dengan
                Hukum yang Berlaku dan memiliki komitmen untuk melindungi Data
                dan/atau Informasi Pribadi semua Pengguna Platform SoBatRP kami.
                Kami mengakui pentingnya Data dan/atau Informasi Pribadi yang
                telah Anda percayakan kepada kami dan percaya bahwa merupakan
                tanggung jawab kami untuk mengelola, melindungi dan mengolah
                Data dan/atau Informasi Pribadi Anda dengan baik sesuai dengan
                Kebijakan Privasi kami. Kebijakan Privasi ini dirancang untuk
                membantu Anda memahami bagaimana kami mengumpulkan, menyimpan,
                mengelola, menggunakan, memproses, mengolah, mentransfer,
                mengungkapkan dan melindungi Data dan/atau Informasi Pribadi
                yang telah Anda percayakan kepada kami dan kami yakin Kebijakan
                Privasi ini dapat membantu Anda dalam membuat keputusan dalam
                mengungkapkan Data dan/atau Informasi Pribadi Anda kepada kami.
                Untuk tujuan pemahaman Kebijakan Privasi ini, kata dalam huruf
                kapital yang digunakan dalam Kebijakan Privasi ini memiliki arti
                sebagai berikut:
                <ol>
                  <li>
                    “Data Pribadi” dan/atau “Informasi Pribadi” berarti data
                    dan/atau informasi, baik benar maupun tidak, mengenai
                    individu atau seorang individu yang dapat diidentifikasi
                    dari data atau informasi tersebut, atau data dan informasi
                    tersebut dapat digunakan untuk mengidentifikasi, menghubungi
                    atau menemukan orang atau perangkat yang terkait dengan data
                    atau informasi tersebut, atau dari data atau informasi
                    lainnya yang dapat atau mungkin dapat diakses oleh suatu
                    organisasi dan dapat diperbaharui dari waktu ke waktu, atau
                    dapat didefinisikan sebagai “data pribadi” atau “informasi
                    pribadi” dalam Hukum yang Berlaku. Contoh dan jenis data
                    atau informasi pribadi yang mungkin kami kumpulkan akan
                    disebutkan pada bagian dibawah ini yang berjudul “Data dan
                    Informasi Pribadi Yang Dikumpulkan”
                  </li>
                  <li>
                    “Hukum yang Berlaku” berarti seluruh peraturan dan
                    perundang-undangan yang terkait dengan perlindungan dan/atau
                    pengelolaan Data dan/atau Informasi Pribadi yang berlaku di
                    Indonesia, termasuk namun tidak terbatas pada Undang-Undang
                    No. 11 Tahun 2018 tentang Informasi dan Transaksi Elektronik
                    (beserta perubahannya), Peraturan Pemerintah No. 71 Tahun
                    2019 tentang Penyelenggaraan Sistem dan Transaksi Elektronik
                    dan Peraturan Menteri Komunikasi dan Informatika No. 20
                    Tahun 2016 tentang Perlindungan Data Pribadi dalam Sistem
                    Elektronik;
                  </li>
                  <li>
                    “Layanan” berarti layanan yang kami sediakan baik melalui
                    Aplikasi maupun Website Resmi SoBatRP sebagaimana dijelaskan
                    dalam Situs kami;
                  </li>
                  <li>
                    “Platform” berarti aplikasi, media sosial, dan website resmi
                    SoBatRP secara kolektif dan website atau aplikasi lainnya
                    yang mungkin kami miliki atau kami kelola dari waktu ke
                    waktu;
                  </li>
                  <li>
                    “Aplikasi” berarti aplikasi resmi SoBatRP pada iOS dan
                    Android dan dalam aplikasi lain yang mungkin kami miliki
                    atau kami kelola dari waktu ke waktu
                  </li>
                  <li>
                    “Media sosial” berarti halaman dan akun resmi SoBatRP pada
                    platform media sosial milik pihak ketiga seperti Instagram,
                    Facebook, Tiktok, Twitter, Pinterest dan Youtube;
                  </li>
                  <li>
                    “Website” berarti website resmi SoBatRP yang dapat diakses
                    melalui alamat www.SobatRp.com
                  </li>
                  <li>
                    “SoBatRP, kami, atau milik kami” berarti PT Sobatrp Solusi
                    Terbarukan termasuk tapi tidak terbatas pada group
                    perusahaan, atau anak perusahaan, atau anak perusahaan dari
                    group perusahaan (termasuk individu, group, mitra dan
                    entitas lainnnya) dari PT Sobatrp Solusi Terbarukan.
                  </li>
                </ol>
              </Terms.Text>
              <Terms.Text>
                Kecuali ditentukan lain, semua istilah dalam huruf kapital yang
                digunakan dalam Kebijakan Privasi ini akan memiliki arti yang
                sama dengan istilah tersebut dalam Ketentuan Penggunaan yang
                berlaku antara Anda dan SoBatRP.
              </Terms.Text>
              <Terms.Text>
                Kebijakan Privasi ini berlaku untuk semua pengguna Platform kami
                (termasuk aplikasi pengguna, aplikasi distributor, dan aplikasi
                subdistributor/agen), kecuali secara tegas diatur pada kebijakan
                privasi yang terpisah.
              </Terms.Text>
              <Terms.Text>
                Kebijakan Privasi ini disediakan dalam format berlapis sehingga
                Anda dapat memilih atau mengklik ke area tertentu yang tersedia
                di bawah ini. Harap baca kebijakan Privasi ini secara menyeluruh
                untuk memastikan bahwa Anda memahami praktik perlindungan data
                kami. Agar lebih mudah dimengerti, kami sudah merangkum
                poin-poin pentingnya di bagian ringkasan.
              </Terms.Text>
              <Terms.ContentBox>
                <Terms.TitleSmall as='h5'>Ringkasan</Terms.TitleSmall>
                <Terms.Text>
                  Dengan menggunakan Layanan, mendaftarkan akun pada kami,
                  mengunjungi Platform kami, atau mengakses Layanan, Anda
                  mengakui dan setuju bahwa Anda menerima praktik, persyaratan,
                  dan/atau kebijakan yang diuraikan dalam Kebijakan Privasi ini,
                  dan Anda dengan ini mengizinkan kami untuk mengumpulkan,
                  menyimpan, mengelola, menggunakan, mengolah, mentransfer
                  dan/atau mengungkapkan data atau informasi pribadi Anda
                  seperti yang dijelaskan pada Kebijakan Privasi ini.
                </Terms.Text>
                <Terms.Text>
                  Dengan mengklik “Ya” atau menerima pop-up Kebijakan Privasi
                  kami atau dari website lain yang merujuk kepada Kebijakan
                  Privasi ini dari platform online lainnya, atau memberikan data
                  atau informasi Pribadi Anda kepada kami saat mendaftarkan akun
                  pada Website atau Aplikasi, atau melakukan browsing pada
                  Website atau Aplikasi, atau memesan produk dan/atau
                  menggunakan jasa kami, atau menyerahkan data atau informasi
                  Anda kepada kami saat berpartisipasi dalam kegiatan yang
                  diselenggarakan oleh Platfrom, atau mendaftarkan diri Anda
                  dalam program afiliasi, program community influencer atau
                  program lainnya yang diselenggarakan oleh SoBatRP, berarti
                  Anda telah memberikan persetujuan Anda atas isi dari Kebijakan
                  Privasi ini.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as='h5'>Security</Terms.TitleSmall>
                <Terms.Text>
                  By purchasing or downloading resource (“item” or “file”) you
                  are being granted a license to use these files for specific
                  uses under certain conditions. Ownership remains with UXTheme,
                  and you are required to abide by the following licensing
                  terms.
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    You have rights for royalty free use of our resources for
                    any or all of your personal and commercial projects.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    You are not required to attribute or link to UXTheme in any
                    of projects.
                  </Terms.ListItem>
                  <Terms.ListItem>
                    We reserve the rights to change prices and revise the
                    resources usage policy in any moment.
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as='h5'>
                  Embedded content from other websites
                </Terms.TitleSmall>
                <Terms.Text>
                  Articles on this site may include embedded content (e.g.
                  videos, images, articles, etc.). Embedded content from other
                  websites behaves in the exact same way as if the visitor has
                  visited the other website.
                </Terms.Text>
                <Terms.Text>
                  {' '}
                  These websites may collect data about you, use cookies, embed
                  additional third-party tracking, and monitor your interaction
                  with that embedded content, including tracking your
                  interaction with the embedded content if you have an account
                  and are logged in to that website.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as='h5'>Changes about terms</Terms.TitleSmall>
                <Terms.Text>
                  If we change our terms of use we will post those changes on
                  this page. Registered users will be sent an email that
                  outlines changes made to the terms of use.
                </Terms.Text>
              </Terms.ContentBox>
            </Terms.Content>
          </Col>
        </Row>
      </Container>
    </Terms>
  )
}
